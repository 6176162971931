import { Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, TextField, Typography } from "@mui/material";
import UtaliiDatePickerInput from "../../../Desktop/sections/custom-date-picker-start_and_end";
import { useLayoutEffect, useState } from "react";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";
import { EnumTipo, formatarMoeda, getCurrencySymbol } from "../../../../../enums/enumPrestacaoDeContas";
import { Add, Delete, EditOutlined } from "@mui/icons-material";
import Flag from "react-world-flags";
import { grey } from "../../../../../theme/palette";

export const NovaViagemComponentM = ({ model, setModel }) => {
    const [centrodecusto, setcentrodecusto] = useState(0);
    const [custo, setCusto] = useState(
        { tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.solicitado }
    );
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [gerenciamento, setGerenciamento] = useState("com")
    const [currentTab, setCurrentTab] = useState("Nacional")
    const [moedaValorCampos, setMoedaValorCampos] = useState([
        { tipo_moeda: EnumTipo.moedas.USD.id, total: 0, status: EnumTipo.adiantamento.solicitado }
    ]);
    const [statusAd, setStatusAd] = useState(EnumTipo.adiantamento.solicitado)

    const handleMoedaChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].tipo_moeda = event.target.value;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos, // Atualiza o modelo com os valores atuais
            pedido: true
        }));
    };

    const handleValorChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        const valorString = event.target.value;
        newMoedaValorCampos[index].total = formatarMoeda(valorString);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos,
            pedido: true // Atualiza o modelo com os valores atuais
        }));
    };


    const handleStatusChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].status = event.target.checked ? EnumTipo.adiantamento.solicitado : EnumTipo.adiantamento.naosolicitado;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    const adicionarMaisUmCampo = () => {
        setMoedaValorCampos([...moedaValorCampos, { tipo_moeda: EnumTipo.moedas.USD.id, total: '0.00', status: EnumTipo.adiantamento.solicitado }]);
    };

    const removerCampo = (index) => {
        const newMoedaValorCampos = moedaValorCampos.filter((_, i) => i !== index);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (event.target.value >= 0 && event.target.value <= 10000) {
            setCusto((prevModel) => ({
                ...prevModel,
                total: value,
                status: statusAd
            }));
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: value, status: statusAd }],
                totalinicial: value
            }));
        } else if (value > 10000) {
            setCusto((prevModel) => ({
                ...prevModel,
                total: 10000,
                status: statusAd
            }));
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: value, status: statusAd }],
                totalinicial: value
            }));
        }
    };

    const handleBlur = () => {
        if (custo.total < 0) {
            setCusto((prevModel) => ({
                ...prevModel,
                total: 0
            }));
        } else if (custo >= 10000) {
            setCusto((prevModel) => ({
                ...prevModel,
                total: 10000
            }));
        }
    };

    useLayoutEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            setCentrosCusto(response);
        })()
    }, [])

    const handleChange = (event) => {
        if (event.target.value === "com") {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: custo.total, status: EnumTipo.adiantamento.solicitado }],
                totalinicial: custo.total,
                pedido: true
            }));
            setStatusAd(EnumTipo.adiantamento.solicitado)
        }
        if (event.target.value === "sem") {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: custo.total, status: EnumTipo.adiantamento.naosolicitado }],
                totalinicial: custo.total,
                pedido: false
            }));
            setStatusAd(EnumTipo.adiantamento.naosolicitado)
        }
        if (event.target.value === "livre") {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
                totalinicial: 0,
                pedido: false
            }));
            setStatusAd(EnumTipo.adiantamento.naosolicitado)
        }
        setGerenciamento(event.target.value);
    };

    const handleChangeTituloDaViagem = (e) => {
        let titulo = e.target.value
        setModel(prevModel => ({
            ...prevModel,
            titulo: titulo
        }));
    }

    const handleChangeCentrodecusto = (value) => {
        setcentrodecusto(value);
        setModel(prevModel => ({
            ...prevModel,
            id_centrocusto: value
        }));
    };

    const handleChangeCusto = (e) => {
        setCusto((prevModel) => ({
            ...prevModel,
            total: e.target.value,
            status: statusAd
        }))
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: e.target.value, status: statusAd }],
            totalinicial: e.target.value,
        }));
    }

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        if (newValue[0] && newValue[1]) {
            setModel(prevModel => ({
                ...prevModel,
                datainicio: newValue[0].toDate(),
                datafim: newValue[1].toDate()
            }));
        }
    };

    const handleChangeTab = (value) => {
        setCurrentTab(value)
        if (value === "Internacional") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: true,
                tipo_moeda: 0,
                totalinicial: 0,
                pedido: true
            }));
        } else {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
                tem_multimonetario: false,
                tipo_moeda: 0,
                totalinicial: 0,
                pedido: false
            }));
        }
    }

    return (
        <>
            <Grid container item xs={12} spacing={1}>
                <Grid
                    container
                    item xs={12}
                    style={{ boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.10)", borderRadius: "10px", padding: "20px", margin: "auto", marginTop: "15px" }}
                >
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            onChange={(e) => handleChangeTituloDaViagem(e)}
                            label="Título da viagem"
                            placeholder="Viagem para..."
                            style={{ borderRadius: "15px" }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "14px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Centro de Custo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={centrodecusto}
                                defaultValue={0}
                                label="Centro de custo"
                            >
                                {centrosCusto?.map((e) => (
                                    <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto(e.value)}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "7px", fontFamily: "sans-serif" }}>
                        <UtaliiDatePickerInput
                            value={selectedDate}
                            onChange={handleDateChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: "0px", padding: "5px" }}>
                    <Grid item xs={12} style={{ marginBottom: "15px", marginTop: "-5px", display: "flex", justifyContent: "center" }}>
                        <ButtonGroup
                            variant="outlined"
                            color="inherit"
                            style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
                        >
                            <Button
                                size="large"
                                onClick={() => handleChangeTab('Nacional')}
                                style={{
                                    textTransform: "none", // Mantém a capitalização original do texto
                                    fontWeight: currentTab === 'Nacional' ? 600 : 400, // Destaca o botão ativo
                                    borderColor: currentTab === 'Nacional' ? "#1C252E" : "#D6D6D6",
                                    backgroundColor: currentTab === 'Nacional' ? "#1C252E" : "#FFFFFF",
                                    color: currentTab === 'Nacional' ? "#FFFFFF" : "#1C252E",
                                    borderRadius: "8px 0 0 8px" // Aplica borderRadius apenas nos extremos
                                }}
                            >
                                Nacional
                            </Button>
                            <Button
                                size="large"
                                onClick={() => handleChangeTab('Internacional')}
                                style={{
                                    textTransform: "none", // Mantém a capitalização original do texto
                                    fontWeight: currentTab === 'Internacional' ? 600 : 400, // Destaca o botão ativo
                                    borderColor: currentTab === 'Internacional' ? "#1C252E" : "#D6D6D6",
                                    backgroundColor: currentTab === 'Internacional' ? "#1C252E" : "#FFFFFF",
                                    color: currentTab === 'Internacional' ? "#FFFFFF" : "#1C252E",
                                    borderRadius: "0 8px 8px 0" // Aplica borderRadius apenas nos extremos
                                }}
                            >
                                Internacional
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    {currentTab === 'Nacional' && (
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Controle de Saldo:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={gerenciamento}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="com" control={<Radio />} label="Solicitar adiantamento" />
                                        <FormControlLabel value="sem" control={<Radio />} label="Sem adiantamento" />
                                        <FormControlLabel value="livre" control={<Radio />} label="Saldo livre" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {gerenciamento !== "livre" ? (
                                <>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontWeight: "500", fontSize: "14px" }}>Solicitar Adiantamento</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={custo.total}
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                max: 10000,
                                                type: 'number',
                                                startAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditOutlined />
                                                    </InputAdornment>
                                                ),
                                                style: { fontSize: '38px', fontWeight: 'bold' }
                                            }}
                                            variant="standard"
                                            style={{ width: '140px', fontSize: '38px', fontWeight: 'inherit' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0px 20px 0px 20px" }}>
                                        <Slider
                                            marks
                                            value={custo.total}
                                            step={100}
                                            onChange={(e) => handleChangeCusto(e)}
                                            max={10000}
                                            min={0}
                                            size="medium"
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ color: "#414141" }}>
                                        Seu saldo será calculado de acordo com suas despesas. Se você não precisa solicitar um adiantamento, suas despesas serão adicionadas ao saldo total que será pago no final da viagem.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {currentTab === 'Internacional' && (
                        <Grid container item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: "5px", marginBottom: "0px" }}>Operação:</FormLabel>
                                {moedaValorCampos.map((campo, index) => (
                                    <Grid container item xs={12} key={index} style={{ marginBottom: '15px' }}>
                                        <Grid item xs={12} container spacing={2} style={{ marginBottom: '0px', marginTop: "0px" }}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                                    <InputLabel id={`select-moeda-label-${index}`}>Moeda</InputLabel>
                                                    <Select
                                                        labelId={`select-moeda-label-${index}`}
                                                        id={`select-moeda-${index}`}
                                                        value={campo.tipo_moeda}
                                                        onChange={(event) => handleMoedaChange(index, event)}
                                                        label="Moeda"
                                                    >
                                                        {Object.keys(EnumTipo.moedas).map((key) => {
                                                            const item = EnumTipo.moedas[key];
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Valor"
                                                    value={campo.total ? formatarMoeda(String(campo.total)) : ''}
                                                    onChange={(event) => handleValorChange(index, event)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {getCurrencySymbol(campo.tipo_moeda)}
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            inputMode: 'numeric',
                                                            style: { fontSize: '18px', fontWeight: 'bold' }
                                                        }
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", flexDirection: "column", alignItems: "flex-end", marginTop: "20px" }}>
                                                <FormGroup>
                                                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                        label="Solicitar adiantamento"
                                                        labelPlacement="start"
                                                        control={
                                                            <Checkbox
                                                                size="large"
                                                                checked={campo.status === EnumTipo.adiantamento.solicitado}
                                                                onChange={(event) => handleStatusChange(index, event)}
                                                            />
                                                        } />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        {index !== 0 && (
                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button startIcon={<Delete />} color="error" size="large" fullWidth onClick={() => removerCampo(index)} variant="outlined">Remover</Button>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Divider variant="ul" style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}></Divider>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item xs={12} style={{ textAlign: "right" }}>
                                    <Button startIcon={<Add />} size="large" onClick={adicionarMaisUmCampo} variant="contained" style={{ color: "#FFFFFF", backgroundColor: grey[800] }}>Nova moeda</Button>
                                </Grid>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export const ConfirmButtonNovaViagemM = ({ confirmFunction }) => {
    return (
        <Grid item xs={12} style={{ padding: "10px" }}>
            <Button size="large" variant="contained" color="primary" fullWidth autoFocus onClick={() => confirmFunction()}>
                Finalizar
            </Button>
        </Grid>
    )
}