import React, { useEffect, useState } from 'react';
import { Button, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Box, RadioGroup, FormControlLabel, Radio, Icon, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, IconButton, TableBody, Divider } from "@mui/material";
import { EnumTipo, formatarMoeda, formatarParaAuditado, formatarParaMoeda, formatarParaMoedaStringComFlag, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusAdiantamentoBool, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem, formatarValorComMoeda, getCurrencySymbol } from "../../../../enums/enumPrestacaoDeContas";
import { CreationDate, IniciadoEm } from "../../../prestacaodecontas/Desktop/sections";
import { DeskCardPersonExpanses } from "../../../../components/card_person_expanses";
import { grey } from '../../../../theme/palette';
import { EnumFormaPagamento, formatarFormaPagamentoIcon, formatarFormaPagamentoString } from '../../../../enums/enumFinanceiro';
import { useCustomSnackbar } from '../../../../components/snackbar2/NewSnackBar';
import financeiroRepositorio from '../../../../repositorios/financeiro';
import prestacaoDeContasRepositorio from '../../../../repositorios/prestacaodecontas';
import ULoaderVoo from '../../../../components/uloadervoo';
import DialogComponent from '../../../prestacaodecontas/Desktop/sections/dialogs-desktop';
import { CreditCard, CreditCardOutlined, MoreVert, PixOutlined } from '@mui/icons-material';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import Flag from 'react-world-flags';
import { ButtonDialogComprovanteInter } from '../../../../components/autalii_expanses/Comprovantes/adiantamento_inter';
import { IconsSourceSvg } from '../../../../images/Icons';


export const PagamentoComponent = ({ viagem, setOpen, getAllViagens }) => {
    const [loading, setLoading] = useState(false);
    const [openNegarAd, setOpenNegarAd] = useState(false);
    const [adiantamentos, setAdiantamentos] = useState([]);
    const { enqueueSnackbar } = useCustomSnackbar();
    const [selectedValue, setSelectedValue] = useState(EnumFormaPagamento.formas.credito);
    const [history, setHistory] = useState([]);
    const [extras, setExtras] = useState([]);
    const [despesas, setDespesas] = useState([])
    const [payViagem, setPayViagem] = useState({
        data_pag: new Date(),
        total: 0,
        forma_pagamento: 1,
        nota: "",
        desconto: 0,
        idpagamento: 0,
    })
    const [openComprovante, setOpenComprovante] = useState(false)
    const [viewAdiantamento, setViewAdiantamento] = useState(null)


    const handleChangeViagem = (event) => {
        const { name, value } = event.target;
        setPayViagem((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChange = (index, event) => {
        const newMoedaValorCampos = [...adiantamentos];
        const valorString = parseFloat(event.target.value);

        newMoedaValorCampos[index].forma_pagamento = valorString;
        setAdiantamentos(newMoedaValorCampos);
    };

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas)
            setExtras(response.extras)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getHistory = async () => {
        setLoading(true)
        try {
            const response = await financeiroRepositorio.getHistory(viagem.id)
            setHistory(response)
        } catch {
            enqueueSnackbar('Erro ao obter historico.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleValorChange = (index, event) => {
        const newMoedaValorCampos = [...adiantamentos];
        let valorString = event.target.value;

        // Remover pontos (separadores de milhar) e substituir vírgula por ponto (decimal)
        valorString = formatarMoeda(valorString).replace(/\./g, '').replace(',', '.');

        const valorNumerico = parseFloat(valorString);

        // Atualizar o valor no estado
        newMoedaValorCampos[index].totalapagar = valorNumerico;
        setAdiantamentos(newMoedaValorCampos);
    };

    const handleMoedaChange = (index, event) => {
        const newMoedaValorCampos = [...adiantamentos];
        let valorString = event.target.value;

        newMoedaValorCampos[index].moedapagar = valorString;
        setAdiantamentos(newMoedaValorCampos);
    };

    const handleChangeTotal = (event) => {
        const valorString = event.target.value;

        setPayViagem((prevModel) => ({
            ...prevModel,
            total: formatarMoeda(valorString) // Atualiza o modelo com os valores atuais
        }));
    }

    const handleChangeNota = (index, event) => {
        const newMoedaValorCampos = [...adiantamentos];
        let valorString = event.target.value;

        const valorNumerico = parseFloat(valorString);

        // Atualizar o valor no estado
        newMoedaValorCampos[index].nota = valorNumerico;
        setAdiantamentos(newMoedaValorCampos);
    }


    const handleChangePay = (event) => {
        const valorString = event.target.value;

        setPayViagem((prevModel) => ({
            ...prevModel,
            forma_pagamento: valorString // Atualiza o modelo com os valores atuais
        }));
    }

    const handleDescontoChange = (index, event) => {
        const newMoedaValorCampos = [...adiantamentos];
        let valorString = event.target.value;

        // Remover pontos (separadores de milhar) e substituir vírgula por ponto (decimal)
        valorString = formatarMoeda(valorString).replace(/\./g, '').replace(',', '.');

        const valorNumerico = parseFloat(valorString);

        // Atualizar o valor no estado
        newMoedaValorCampos[index].totaldesconto = valorNumerico;
        setAdiantamentos(newMoedaValorCampos);
    };

    useEffect(() => {
        const adiantamentosClone = structuredClone(viagem.adiantamento).map(adiantamento => ({
            ...adiantamento,
            forma_pagamento: 1,
            desconto: 0,
            nota: 'Nada a informar',
            totalapagar: adiantamento.total,
            totalpedido: adiantamento.total,
            moedapagar: adiantamento.tipo_moeda,
            totaldesconto: 0
        }));

        setAdiantamentos(adiantamentosClone);
        getHistory();
        getDespesas();
    }, [viagem]);


    const validateFields = () => {
        for (let i = 0; i < adiantamentos.length; i++) {
            const { forma_pagamento, desconto, total, nota } = adiantamentos[i];
            if (!forma_pagamento) {
                enqueueSnackbar(`Forma de pagamento não informada para o adiantamento ${i + 1}.`, { variant: 'warning' });
                return false;
            }
            if (!total) {
                enqueueSnackbar(`Total não informado para o adiantamento ${i + 1}.`, { variant: 'warning' });
                return false;
            }
        }
        return true;
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClickPagarTodos = async () => {
        if (!validateFields()) return;

        setLoading(true);
        try {
            let adiantamentoslist = []
            for (const adiantamento of adiantamentos) {
                const info = {
                    data_pag: new Date(),
                    total: adiantamento.total,
                    forma_pagamento: adiantamento.forma_pagamento,
                    nota: adiantamento.nota,
                    desconto: adiantamento.desconto,
                    idpagamento: adiantamento.idpagamento,
                };
                adiantamentoslist.push(info)
            }
            await financeiroRepositorio.putPagarAdiantamento(viagem.id, adiantamentoslist);
            getAllViagens();
            enqueueSnackbar('Todos os adiantamentos foram pagos com sucesso.', { variant: 'success' });
            setOpen(false);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao realizar os pagamentos.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleClickPagarTodasViagem = async () => {
        if (!validateFields()) return;

        setLoading(true);
        try {
            let adiantamentoslist = []
            for (const adiantamento of adiantamentos) {
                const info = {
                    data_pag: new Date(),
                    total: adiantamento.total,
                    forma_pagamento: adiantamento.forma_pagamento,
                    nota: adiantamento.nota,
                    desconto: adiantamento.desconto,
                    idpagamento: adiantamento.idpagamento,
                    tipo_moeda: adiantamento.moedapagar
                };
                adiantamentoslist.push(info)
            }

            await financeiroRepositorio.putPagarViagem(viagem.id, adiantamentoslist);
            getAllViagens();
            enqueueSnackbar('Viagem paga com sucesso.', { variant: 'success' });
            setOpen(false);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao pagar a viagem.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleClickNegarTodos = async () => {
        setLoading(true);
        try {
            for (const adiantamento of adiantamentos) {
                await financeiroRepositorio.putNegarAdiantamento(viagem.id, adiantamento.id);
            }
            getAllViagens();
            enqueueSnackbar('Todos os adiantamentos foram negados com sucesso.', { variant: 'success' });
            setOpen(false);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao negar os adiantamentos.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container spacing={2} sx={{ p: 0 }}>
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={6} style={{ padding: "20px", height: "fit-content" }} spacing={2}>
                <Grid item xs={12} container>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IniciadoEm datainicio={viagem.datainicio} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start", textAlign: "end" }}>
                        <CreationDate data={viagem.datadecriacao} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Grid style={{
                            display: "flex",
                            alignItems: "flex-end",
                            padding: "10px",
                        }}>
                            <Typography variant="subtitle1">Todas as despesas</Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="h6" color="textSecondary" sx={{ mb: 1, fontSize: "14px" }}>
                                {formatarParaNomeStatusViagem(viagem.status)}
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container item xs={12}>
                    {despesas?.length != 0 ? (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Metódo</TableCell>
                                            <TableCell align="right">Total</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {despesas?.map((transaction, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">{transaction.iddespesa}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">{capitalizeFirstLetter(format(new Date(transaction.datacriacao), "EEE'.' d MMM 'de' yyyy", { locale: ptBR }))}</Typography>
                                                    <Typography variant="caption" color="textSecondary">{transaction.despesa_desc}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body2" fontWeight="bold" color="primary">{transaction.aprovado ? ("Aprovado") : ("Negado")}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body2" fontWeight="bold">{formatarValorComMoeda(transaction.tipo_moeda, transaction.total)}</Typography>
                                                </TableCell>
                                                {/* <TableCell align="right">
                                                    <IconButton size="small">
                                                        <MoreVert />
                                                    </IconButton>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                <img src={IconsSourceSvg.pastas}></img>
                                <Typography>Nenhuma despesa encontrada</Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={6} style={{ padding: "40px" }}>
                <DialogComponent
                    title={"Baixar comprovante"}
                    open={openComprovante}
                    handleClose={() => setOpenComprovante(false)}
                    body={
                        <Grid container item xs={12}>
                            {viewAdiantamento !== null && (
                                <ButtonDialogComprovanteInter adiantamento={viewAdiantamento} />
                            )}
                        </Grid>
                    }
                    action={
                        <Grid>
                            <Button>Baixar comprovante</Button>
                            {/* <Button>Confimar</Button> */}
                        </Grid>
                    }
                    width={"sm"}
                />
                {adiantamentos.map((adiantamento, index) => (
                    <>
                        {adiantamento.status === EnumTipo.adiantamento.aprovado && (
                            <Grid key={index} item xs={12} container spacing={2} style={{ marginBottom: '10px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Detalhes do pagamento</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {formatarParaNomeStatusAdiantamento(adiantamento.status)}
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Button onClick={() => { setViewAdiantamento(adiantamento); setOpenComprovante(true) }} variant="outlined" fullWidth color="primary">Baixar comprovante PDF</Button>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Moeda pedida</Typography>
                                        <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>
                                            {formatarParaMoedaStringComFlag(adiantamento.tipo_moeda, adiantamento.total)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em </Typography>
                                        <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarParaMoeda(adiantamento.tipo_moeda, adiantamento.total)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em reais</Typography>
                                        <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}</Typography>
                                    </Box>
                                </Grid>
                                {viagem.status === EnumTipo.viagemstatus.financeiro && (
                                    <Grid item xs={12} container spacing={2} style={{ marginBottom: '10px' }}>
                                        <Grid item xs={12} style={{ padding: "20px" }}>
                                            <Divider variant="ul" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Pagar a viagem</Typography>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                        {formatarParaNomeStatusAdiantamento(adiantamento.status)}
                                    </Grid> */}
                                        <Grid item xs={12} container spacing={2} justifyContent={"right"}>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                                    <InputLabel id={`select-moeda-label-${index}`}>Moeda</InputLabel>
                                                    <Select
                                                        labelId={`select-moeda-label-${index}`}
                                                        id={`select-moeda-${index}`}
                                                        value={adiantamento.moedapagar}
                                                        onChange={(event) => handleMoedaChange(index, event)}
                                                        label="Moeda"
                                                    >
                                                        {Object.keys(EnumTipo.moedas).map((key) => {
                                                            const item = EnumTipo.moedas[key];
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Desconto"
                                                    name="desconto"  // Associando ao nome da propriedade no estado
                                                    value={adiantamento.totaldesconto ?
                                                        formatarMoeda(String(adiantamento.totaldesconto.toFixed(2)))
                                                        : ''}
                                                    onChange={(event) => handleDescontoChange(index, event)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {getCurrencySymbol(adiantamento.moedapagar)}
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            inputMode: 'numeric',
                                                            style: { fontSize: '18px', fontWeight: 'bold' }
                                                        }
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Total"
                                                    name="total"  // Associando ao nome da propriedade no estado
                                                    value={adiantamento.totalapagar ? formatarMoeda(String(adiantamento.totalapagar.toFixed(2))) : ''}
                                                    onChange={(event) => handleValorChange(index, event)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {getCurrencySymbol(adiantamento.moedapagar)}
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            inputMode: 'numeric',
                                                            style: { fontSize: '18px', fontWeight: 'bold' }
                                                        }
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Nota"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                name="nota"  // Associando ao nome da propriedade no estado
                                                defaultValue={adiantamento.nota}
                                                onChange={handleChangeViagem}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <FormControl component="fieldset" style={{ width: "100%", padding: "20px" }}>
                                            <Typography variant="subtitle1" style={{ marginBottom: '8px', color: grey[800] }}>
                                                Selecione o método de pagamento
                                            </Typography>
                                            <RadioGroup
                                                name="forma_pagamento"  // Associando ao nome da propriedade no estado
                                                value={adiantamento.forma_pagamento || 0}
                                                onChange={(e) => handleChange(index, e)}
                                            >
                                                <Grid container item xs={12}>
                                                    {Object.entries(EnumFormaPagamento.formas).map(([key, value]) => (
                                                        <Grid item xs={6} key={key}>
                                                            <FormControlLabel
                                                                value={value}
                                                                control={<Radio style={{ color: grey[800] }} />}
                                                                label={
                                                                    <Box display="flex" alignItems="center">
                                                                        {formatarFormaPagamentoIcon(value)}
                                                                        <Typography variant="body1" style={{ color: grey[800] }}>
                                                                            {formatarFormaPagamentoString(value)}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                                style={{
                                                                    backgroundColor: '#FEFEFE',
                                                                    borderRadius: '8px',
                                                                    padding: '6px',
                                                                    marginBottom: '8px',
                                                                    border: adiantamento.forma_pagamento == value ? '2px solid ' + grey[800] : '1px solid transparent',
                                                                    width: "100%"
                                                                }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {adiantamento.status === EnumTipo.adiantamento.solicitado && (
                            <Grid key={index} item xs={12} container spacing={2} style={{ marginBottom: '10px' }}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Detalhes do pagamento</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {formatarParaNomeStatusAdiantamento(adiantamento.status)}
                                </Grid>
                                {adiantamento.tipo_moeda !== EnumTipo.moedas.BRL.id ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Moeda pedida</Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>
                                                    {formatarParaMoedaStringComFlag(adiantamento.tipo_moeda, adiantamento.total)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em </Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarParaMoeda(adiantamento.tipo_moeda, adiantamento.total)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em reais</Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}</Typography>
                                            </Box>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor do adiantamento</Typography>
                                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}</Typography>
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} container spacing={2} justifyContent={"right"}>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Desconto"
                                            value={adiantamento.totaldesconto ?
                                                formatarMoeda(String(adiantamento.totaldesconto.toFixed(2)))
                                                : ''}
                                            onChange={(event) => handleDescontoChange(index, event)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {getCurrencySymbol(adiantamento.moedapagar)}
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    inputMode: 'numeric',
                                                    style: { fontSize: '18px', fontWeight: 'bold' }
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Total"
                                            value={adiantamento.totalapagar ? formatarMoeda(String(adiantamento.totalapagar.toFixed(2))) : ''}
                                            onChange={(event) => handleValorChange(index, event)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {getCurrencySymbol(adiantamento.moedapagar)}
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    inputMode: 'numeric',
                                                    style: { fontSize: '18px', fontWeight: 'bold' }
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Nota"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={adiantamento.nota}
                                        onChange={(e) => handleChange(index, 'nota', e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>

                                <FormControl component="fieldset" style={{ width: "100%", padding: "20px" }}>
                                    <Typography variant="subtitle1" style={{ marginBottom: '8px', color: grey[800] }}>
                                        Selecione o metodo de pagamento
                                    </Typography>
                                    <RadioGroup
                                        value={adiantamento.forma_pagamento || 0}
                                        onChange={(e) => handleChange(index, e)}
                                    >
                                        <Grid container item xs={12}>
                                            {Object.entries(EnumFormaPagamento.formas).map(([key, value]) => (
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value={value}
                                                        control={<Radio style={{ color: grey[800] }} />}
                                                        fullWidth
                                                        label={
                                                            <Box display="flex" alignItems="center">
                                                                {formatarFormaPagamentoIcon(value)}
                                                                <Typography variant="body1" style={{ color: grey[800] }}>
                                                                    {formatarFormaPagamentoString(value)}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        style={{
                                                            backgroundColor: '#FEFEFE',
                                                            borderRadius: '8px',
                                                            padding: '6px',
                                                            marginBottom: '8px',
                                                            border: adiantamento.forma_pagamento == value ? '2px solid ' + grey[800] : '1px solid transparent',
                                                            width: "100%"
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                                <Divider variant="ul" />
                            </Grid>
                        )}
                    </>
                ))}

                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                    {viagem.status === EnumTipo.viagemstatus.financeiro ? (
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={handleClickPagarTodasViagem}>Finalizar viagem</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {formatarParaNomeStatusAdiantamentoBool(viagem.adiantamento) !== "pendente" && (
                                <>
                                    <Button onClick={handleClickNegarTodos} variant="outlined" style={{ backgroundColor: grey[800], color: "white" }}>Negar Todos</Button>
                                    <Button variant="contained" color="primary" onClick={handleClickPagarTodos}>Salvar e Pagar Todos</Button>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PagamentoComponent;
