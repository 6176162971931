import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useMediaQuery, useTheme, IconButton, Box } from '@mui/material';
import { ClearOutlined } from '@mui/icons-material';
import { grey } from '../../../../theme/palette';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateRangePicker, MobileDateRangePicker, SingleInputDateRangeField, ptBR, StaticDateRangePicker, pickersLayoutClasses } from '@mui/x-date-pickers-pro';

// Configure dayjs
dayjs.extend(localizedFormat);
dayjs.locale('pt-br');

export default function UtaliiDatePickerInput({
    value,
    onChange,
    minDate,
    maxDate,
    shouldDisableDate = false,
    showDirectCalendar = false // Nova propriedade para controle
}) {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClearDates = () => {
        onChange([null, null]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}>
            <DemoContainer components={['DateRangePicker', 'MobileDateRangePicker', 'DateRangeCalendar']}>
                {showDirectCalendar ? (
                    <DemoItem component="DateRangeCalendar">
                        <Box
                            sx={{
                                '.MuiPickersDay-root': {
                                    '&.Mui-selected': {
                                        backgroundColor: '#c62828',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: 'darkred',
                                        },
                                    },
                                    '&.Mui-selected.Mui-disabled': {
                                        backgroundColor: 'lightcoral',
                                    },
                                },
                                [`.${pickersLayoutClasses.contentWrapper}`]: {
                                    alignItems: 'left',
                                },
                            }}
                        >
                            <StaticDateRangePicker
                                displayStaticWrapperAs="desktop"
                                value={value}
                                onChange={onChange}
                                // minDate={minDate}
                                // maxDate={maxDate}
                                disabled={!shouldDisableDate}
                                // shouldDisableDate={shouldDisableDate}
                                calendars={1}
                                sx={{
                                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                                        alignItems: 'left',
                                    },
                                }}
                            />
                        </Box>
                    </DemoItem>
                ) : (fullscreen ? (
                    <DemoItem component="MobileDateRangePicker">
                        <MobileDateRangePicker
                            sx={{
                                width: "100%",
                                margin: 0, fontWeight: "700", lineHeight: "1.5555555555555556", fontSize: "1.0625rem", fontFamily: "sans-serif"
                            }}
                            value={value}
                            onChange={onChange}
                            startText="Data de início"
                            endText="Data de término"
                            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            slots={{
                                field: SingleInputDateRangeField,
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            // shouldDisableDate={shouldDisableDate}
                            fullWidth
                            format="DD/MM/YYYY" // Define o formato para DD/MM/ANO
                        />
                    </DemoItem>
                ) : (
                    <DemoItem component="DateRangePicker">
                        <DateRangePicker
                            sx={{ width: "230px" }}
                            value={value}
                            onChange={onChange}
                            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            slots={{
                                field: SingleInputDateRangeField,
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            // shouldDisableDate={shouldDisableDate}
                            format="DD/MM/YYYY" // Define o formato para DD/MM/ANO
                        />
                    </DemoItem>
                ))}
            </DemoContainer>
            {value[0] != null && value[1] != null && !showDirectCalendar && (
                <IconButton
                    onClick={handleClearDates}
                    sx={{ marginTop: 2, color: grey[800] }}
                >
                    <ClearOutlined />
                </IconButton>
            )}
        </LocalizationProvider>
    );
}
