import { AppBar, Box, Button, Grid, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from "react";
import UMultiSelect from "../../../../components/umultiselect";
import USearchField from "../../../../components/usearchfield";
import produtoRepositorio from "../../../../repositorios/produto";
import usuarioRepositorio from "../../../../repositorios/usuario";
import { useStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogSearchU(props) {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const editando = false;
  const [solicitantes, setSolicitantes] = useState([]);
  const classes = useStyles();
  const [itens, setItens] = useState([]);

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    (async () => {
      props.setIsLoading(true);
      try {
        const responseSolicitante = await usuarioRepositorio.getSearchField();
        setSolicitantes(responseSolicitante);
        const responseProdutos = await produtoRepositorio.getAllProduto();
        setItens(responseProdutos.data);
      } catch (error) {
        props.setSnackMessage("Erro ao pesquisar solicitante!");
        props.setSnackVisible({ visible: true });
        props.setSnackVariant("error");
      } finally {
        props.setIsLoading(false);
      }
    })();
  }, [props]);

  return (
    <Grid>
      <Dialog
        open={props.open}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        fullScreen={responsive}
        TransitionComponent={Transition}
      >
        <AppBar position="relative" style={{ background: "none" }}>
          <Toolbar className={classes.appbar}>
            <Typography>Filtro</Typography>
            <IconButton onClick={handleClose}>
              <Close className={classes.iconButton} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <USearchField itens={solicitantes} value={props.solicitante} setValue={props.setSolicitante} label="Solicitante" />
            <form className={classes.root} noValidate>
              <UMultiSelect disabled={editando} value={props.categoriaProduto} setValue={props.setCategoriaProduto} itens={itens} label="Tipo" color="#c62828" />
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handlePesquisa} className={classes.button} autoFocus>
            <SearchIcon></SearchIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default DialogSearchU;
