import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Select, MenuItem, InputAdornment, Divider, Chip, Avatar, IconButton, Dialog, DialogTitle, DialogContent, Card, InputLabel, FormControl, Grow } from '@mui/material';
import { AttachMoney, ArrowForward, VisibilityOff, Visibility, ArrowForwardIos, CurrencyExchange, AccountBalance, AccountBalanceWallet, ArrowBack, ArrowDownward, Height, SwapVert } from '@mui/icons-material';
import Flag from 'react-world-flags';
import { EnumTipo, formatarMoeda, formatarParaMoeda, formatarParaMoedaString, formatarParaNomeStatusAdiantamento, formatarValorComMoeda, getCurrencySymbol } from '../../../enums/enumPrestacaoDeContas';
import { grey } from '../../../theme/palette';
import { UploadAvatar } from '../../../upload';
import { convertImageToBase64 } from '../../../hooks/convertImageToBase64';
import { TrocarMoedaComponent } from '../trocar_moeda';

const TransferComponent = ({ viagem, despesas, totalex = 0.00, totalbrl = 0.00, moeda = EnumTipo.moedas.USD.id, status }) => {
    const [showBalance, setShowBalance] = useState(true);
    const [trocarMoeda, setTrocarMoeda] = useState(false);
    const [mudarSaldo, setMoedaSaldo] = useState(false);
    const [moedaChange, setMoedaChange] = useState(0);
    const [totalmoedaChange, settotalMoedaChange] = useState("0,00");
    const [trocamoedaChange, settrocaMoedaChange] = useState("0,00");
    const [total, setTotal] = useState(0);
    const [file, setFile] = useState(null);

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const GetGastoTotal = () => {
        let total2 = 0;
        despesas.forEach(despesa => {
            if (despesa.tipo_moeda === moeda) {
                total2 += despesa.total;
            }
        });
        setTotal(total2);
    }

    const handleMoedaChange = (e) => {
        setMoedaChange(e.target.value)
    }

    const handleValorChange = (e) => {
        settotalMoedaChange(e.target.value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    useEffect(() => {
        GetGastoTotal()

    }, [despesas])

    return (
        <Box sx={{ maxWidth: 400, p: 3, borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: 'white' }}>
            <Grid container spacing={1}>
                {status === EnumTipo.adiantamento.aprovado ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalex)}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                        {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                    </div>
                                    <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                        {showBalance ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                    Saldo Atual
                                </Typography>
                            </Box>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                        <Button startIcon={<AccountBalanceWallet />} fullWidth variant="contained" style={{ backgroundColor: grey[800], color: "#FFFFFF" }}>Saldo</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TrocarMoedaComponent />
                                    </Grid>
                                </Grid>
                            </Box> */}
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            {formatarParaMoeda(moeda, totalex)}
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em reais</Typography>
                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, totalbrl)}</Typography>
                            </Box>
                        </Grid>

                        <Divider sx={{ my: 2, width: '100%' }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" color="textSecondary" style={{ display: "contents" }}>
                                        {/* <ArrowForward fontSize="small" />  */}
                                        Financeiro
                                    </Typography>
                                </Box>
                                <Typography variant="caption" color="textSecondary">
                                    {formatarParaNomeStatusAdiantamento(status)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default TransferComponent;
