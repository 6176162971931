import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { UploadAvatar } from "../../../../../../upload";
import { UtaliiDatePickerNormal } from "../../../sections/custom-date-picker-normal";
import { useCallback, useEffect, useState } from "react";
import { convertImageToBase64 } from "../../../../../../hooks/convertImageToBase64";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import Flag from "react-world-flags";
import { EnumTipo, formatarMoeda, getCurrencySymbol, getEnumForID } from "../../../../../../enums/enumPrestacaoDeContas";
import ULoaderVoo from "../../../../../../components/uloadervoo";
import { processImage } from "../../../../../../components/autalii_expanses/controlimages";
// import { ExtrasDespesasComponent } from "./Extras";

export const AdicionarDespesaComponent = ({ viagem, handleCreate, setType }) => {
    const [file, setFile] = useState(null);
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("Despesa para operações diárias.");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState("0,00");
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [moeda, setMoeda] = useState(viagem.adiantamento[0]?.tipo_moeda || EnumTipo.moedas.BRL.id); // Moeda padrão (Euro)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setCategorias, enqueueSnackbar]); // Dependências

    const handleMoedaChange = (event) => {
        const novaMoeda = event.target.value;
        setMoeda(novaMoeda);

        if (totalgasto !== '') {
            setTotalgasto(totalgasto); // Mantém o valor atual, mas ele será formatado na apresentação
        }

    };

    const handleCreateDespesa = () => {
        if (file === null || file === "") {
            enqueueSnackbar('Adicione a foto do comprovante.', { variant: 'warning' });
        }

        let totalString = totalgasto.replace(/\./g, '').replace(',', '.');
        // Converte a string para um número float
        let newtotal = parseFloat(totalString);

        const despesadata = {
            idviagem: viagem.id,
            datadecompra: datadecompra,
            descricao: descricao,
            hodometro: null,
            aprovado: false,
            controladoria: false,
            is_extra: false,
            total: newtotal,
            mensagem: "",
            comprovante: file,
            QrCode: null,
            tipo_moeda: moeda
        }

        if (tipodedespesa !== null && tipoGasto !== null) {
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipoGasto.value
            }
            if (despesadata.produtos.iddespesa_tipo !== null && despesadata.produtos.iddespesa_subtipo === null) {
                enqueueSnackbar('Adicione com oque foi gasto.', { variant: 'warning' });
                return
            }
            despesadata.valor_limite = tipoGasto.limite;
        }

        // return

        handleCreate(despesadata)
        setType(0)
    }

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalgasto(valorFormatado);
    };

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            processImage(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    useEffect(() => {
        getAlltipos()
    }, [getAlltipos])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12} style={{ width: "auto", minWidth: "500px" }}>
                <Grid container item xs={4} style={{ justifyContent: "center", textAlign: "left", alignContent: "space-between", marginTop: "10px", padding: "10px" }} spacing={1}>
                    <UploadAvatar
                        file={file}
                        accept="image/jpeg,image/png,image/gif"
                        style={{ height: "auto" }}
                        onDrop={handleDropAvatar}
                        fullScreen={true}
                    // validator={(fileData) => {
                    //     if (fileData.size > 1000000) {
                    //         return {
                    //             code: 'file-too-large',
                    //             message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                    //         };
                    //     }
                    //     return null;
                    // }}
                    />
                </Grid>
                <Grid container item xs={8} style={{ marginTop: "10px", paddingLeft: "10px", alignContent: "center" }} spacing={1}>

                    <Grid item xs={12}>
                        <TextField
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setDescricao(e.target.value)}
                            defaultValue={descricao}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Viagem para..."

                        />
                    </Grid>
                    <Grid item xs={12}>
                        {categorias.length === 0 ? (
                            <Skeleton>
                            </Skeleton>
                        ) : (
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipodedespesa != null ? tipodedespesa.value : null}
                                    defaultValue={0}
                                    label="Categoria"

                                >
                                    {categorias
                                        ?.filter(categoria =>
                                            categoria.name !== 'Hodometro' &&
                                            categoria.name !== 'Outros' &&
                                            categoria.name !== 'Pendente' &&
                                            categoria.name !== 'SOLICITAÇÃO' &&
                                            categoria.name !== 'AEREO' &&
                                            categoria.name !== 'HOTEL'
                                        )
                                        .map((e) => (
                                            <MenuItem
                                                value={e.iddespesa_tipo}
                                                onClick={() => handleChangeTipoDeDespesa({ label: e.name, value: e.iddespesa_tipo, sub: e.produtos })}
                                            >
                                                {e.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    {tipodedespesa !== null && tipodedespesa?.sub?.lenght !== 0 && tipodedespesa?.label !== "Outros" ? (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipoGasto != null ? tipoGasto.value : null}
                                    defaultValue={0}
                                    label="Categoria"

                                >
                                    {tipodedespesa?.sub?.map((e) => (
                                        <MenuItem value={e.iddespesa_subtipo} onClick={() =>
                                            handleChangeGasto({
                                                label: e.name,
                                                value: e.iddespesa_subtipo,
                                                limite: e.limite
                                            })}>{e.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (
                        null
                    )}
                    {viagem.tem_multimonetario && (
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                <InputLabel id="select-moeda-label">Moeda</InputLabel>
                                <Select
                                    labelId="select-moeda-label"
                                    id="select-moeda"
                                    value={moeda}
                                    onChange={handleMoedaChange}
                                    label="Moeda"
                                >
                                    {viagem.adiantamento?.map((i) => {
                                        const item = getEnumForID(i.tipo_moeda);
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                            </MenuItem>
                                        );
                                    })}
                                    <MenuItem key={EnumTipo.moedas.BRL.id} value={EnumTipo.moedas.BRL.id}>
                                        <Flag code={EnumTipo.moedas.BRL.flagCode} width="20" style={{ marginRight: '10px' }} /> {EnumTipo.moedas.BRL.nome}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={6} style={{ paddingRight: "10px" }}>
                        <TextField
                            fullWidth
                            id="outlined-number"
                            label="Total gasto"
                            value={totalgasto}
                            onChange={handleValorChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {getCurrencySymbol(moeda)}
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    inputMode: 'numeric', // Orienta o uso de teclado numérico
                                    style: { fontSize: '18px' }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                        <UtaliiDatePickerNormal
                            value={datadecompra}
                            onChange={setDatadecompra}
                            label="Data de Compra"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={6} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                    <Button
                        onClick={() => setType(0)}
                        startIcon={<ArrowBackIosNewOutlined />}
                        color="primary"
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
                    <Button variant="contained" color="primary" autoFocus onClick={() => handleCreateDespesa()}>
                        Criar despesa
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}