import { Avatar, Button, Container, Grid, Grow, IconButton, InputAdornment, Pagination, Paper, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { EnumTipo, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem } from "../../../../enums/enumPrestacaoDeContas";
import { CreationDate, CriadoPor, IniciadoEm } from "../sections";
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from "./style";
import React, { useCallback, useEffect, useState } from "react";
import DialogComponent from "../sections/dialogs-desktop";
import { ConfirmButtonNovaViagem, NovaViagemComponent } from "./NovaViagem";
import { ConfirmButtonViagem, ViagemComponent } from "./Viagem";
import UtaliiDatePickerInput from "../sections/custom-date-picker-start_and_end";
import SaveIcon from '@mui/icons-material/Save';
import { Delete } from "@mui/icons-material";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import ULoaderVoo from "../../../../components/uloadervoo";
import { stringAvatar } from "../../../../theme/palette";

export const MinhasViagens = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [minhasviagens, setMinhasViagens] = useState([]);
    const [openDeleteViagem, setOpenDeleteViagem] = useState(false)
    const [pessoa, setPessoa] = useState(null)
    const [criarNovaviagem, setCriarNovaViagem] = useState(false);
    const [openViagem, setOpenViagem] = useState(false);
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [viagem, setViagem] = useState(null);
    const [typeDialog, setTypeDialog] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterViagens, setFilterViagens] = useState(minhasviagens)
    const [novaviagem, setNovaviagem] = useState({
        titulo: "",
        id_centrocusto: 0,
        adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.naosolicitado }],
        totalinicial: 0,
        tem_multimonetario: false,
        pedido: false
    });
    const [alterViagem, setAlterViagem] = useState({
        titulo: "",
        datainicial: null,
        datafim: null
    })

    const [page, setPage] = useState(1);
    const itemsPerPage = 5;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterViagens.slice(startIndex, endIndex);

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { enqueueSnackbar } = useCustomSnackbar();

    const handlesearchname = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredViagens = minhasviagens.filter(viagem =>
            viagem.titulo.toLowerCase().includes(searchTerm)
        );

        setPage(1)

        setFilterViagens(filteredViagens);
    }

    const handleChangenameViagem = (e) => {
        let title = e.target.value
        setAlterViagem(prevState => ({
            ...prevState, // Copia todos os pares chave-valor existentes
            titulo: title // Adiciona ou atualiza a chave "titulo" com o novo valor
        }));
    }

    const getMinhasViagens = useCallback(async () => {
        setLoading(true);
        try {
            let model = {
                data_inicial: new Date(new Date().getFullYear(), 0, 1), // Início do ano: 1º de janeiro do ano atual
                data_final: new Date(new Date().getFullYear(), 11, 31), // Fim do ano: 31 de dezembro do ano atual
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: true
            };
    
            if (selectedDate[0] && selectedDate[1]) {
                model.data_inicial = selectedDate[0];
                model.data_final = selectedDate[1];
            }
    
            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);
            const sortedViagens = response.viagens.sort((a, b) => new Date(b.datadecriacao) - new Date(a.datadecriacao));
    
            setMinhasViagens(sortedViagens);
            setPessoa(response.pessoa);
        } catch (error) {
            enqueueSnackbar('Erro ao obter as suas viagens.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setMinhasViagens, setPessoa, selectedDate, enqueueSnackbar]);

    const getViagemForID = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getViagem(viagem.id);
            setViagem(response)
        } catch (error) {
            enqueueSnackbar('Erro ao obter sua viagem.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    const confirmCriarNovaViagem = async () => {
        setLoading(true)
        try {
            if (novaviagem.titulo === "" || novaviagem.titulo === null) {
                enqueueSnackbar('Titulo da viagem não pode ser vazio.', { variant: 'warning' });
                return
            }
            if (novaviagem.id_centrocusto === "" || novaviagem.id_centrocusto === null) {
                enqueueSnackbar('Centro de custo não pode ser vazio.', { variant: 'warning' });
                return
            }

            if (novaviagem.datafim === null) {
                enqueueSnackbar('Defina uma data final.', { variant: 'warning' });
                return
            }

            if (novaviagem.datainicio === null) {
                enqueueSnackbar('Defina uma data inicial.', { variant: 'warning' });
                return
            }

            novaviagem.adiantamento.forEach(viagem => {
                if (typeof viagem.total === 'string') {
                    let totalString = viagem.total.replace(/\./g, '').replace(',', '.');
                    viagem.total = parseFloat(totalString);
                }
            });

            await prestacaoDeContasRepositorio.putNovaViagem(novaviagem);
            enqueueSnackbar('Viagem criada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            setCriarNovaViagem(false)
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditViagem = async () => {
        setLoading(true)
        try {
            setAlterViagem(prevState => ({
                ...prevState, // Copia todos os pares chave-valor existentes
                titulo: "" // Adiciona ou atualiza a chave "titulo" com o novo valor
            }));

            let model = {
                datainicial: alterViagem.datainicial === null ? new Date(viagem.datainicio) : new Date(alterViagem.datainicial),
                datafim: alterViagem.datafim === null ? new Date(viagem.datafim) : new Date(alterViagem.datafim),
                titulo: alterViagem.titulo !== "" && alterViagem.titulo != null ? alterViagem.titulo : viagem.titulo
            }

            await prestacaoDeContasRepositorio.putEditViagem(viagem.id, model);
            enqueueSnackbar('Viagem Editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickViagem = (viagem) => {
        setViagem(viagem)
        setOpenViagem(true)
    }

    const handleClickCriarDespesa = async (despesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(despesa);
            enqueueSnackbar('Despesa criada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
            // reload() 
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditDespesa = async (despesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(despesa)
            enqueueSnackbar('Despesa editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteDespesa = async (despesa) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.deleteDespesa(despesa.id)
            enqueueSnackbar('Despesa excluida com sucesso.', { variant: 'success' });
            await getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao excluida despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleIniciarViagem = async (idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(idviagem)
            getMinhasViagens()
            getViagemForID()
            enqueueSnackbar('Viagem iniciada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao iniciar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleFinalizarViagem = async (idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(idviagem)
            getMinhasViagens()
            getViagemForID()
            enqueueSnackbar('Viagem finalizada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao finalizada viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluirViagem = async (idviagem) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.deleteViagem(idviagem)
            setOpenDeleteViagem(false)
            setOpenViagem(false)
            getMinhasViagens()
            enqueueSnackbar('Viagem deletada com sucesso.', { variant: 'success' });
        } catch (error) { // Correção: incluído o parâmetro de erro no catch
            if (error.response && error.response.status === 409) {
                enqueueSnackbar('Não foi possível excluir a despesa devido a um conflito.', { variant: 'warning' });
            } else {
                enqueueSnackbar('Ocorreu um erro ao excluir a despesa.', { variant: 'error' });
            }
        } finally {
            setLoading(false)
        }
    }

    const handleClickCreateHodometro = async (hodometro) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(hodometro)
            enqueueSnackbar('Adicionado Hodometro manual.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao adicionar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditHodometro = async (hodometro) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(hodometro)
            enqueueSnackbar('Editado Hodometro com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editadar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    useEffect(() => {
        setFilterViagens(minhasviagens)
    }, [minhasviagens])

    useEffect(() => {
        getMinhasViagens();
    }, [getMinhasViagens])

    return (
        <>
            <Container maxWidth="lg">
                <ULoaderVoo isLoading={loading} />
                <Grid container item xs={12}>
                    <Grid item xs={4}>
                        <Paper
                            sx={{
                                py: 2,
                                textAlign: 'center',
                                boxShadow: theme.customShadows,
                                position: "sticky",
                                top: 80,
                                borderRadius: "10px"
                            }}
                        >
                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    {pessoa != null && (
                                        <Avatar
                                            size={5}
                                            src={pessoa?.img}
                                            {...stringAvatar(pessoa?.nome)}
                                            style={{ width: 56, height: 56, marginRight: "-16px" }}

                                        />
                                    )}
                                    <Typography style={{ fontWeight: "600", marginTop: "8px" }}>{pessoa?.nome}</Typography>
                                    <Typography style={{ color: "#919EAB" }}>{pessoa?.centrocusto}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" size="large" color="primary" fullWidth onClick={() => setCriarNovaViagem(true)} id="#demo__1">
                                        Abrir viagem
                                    </Button>
                                    <DialogComponent
                                        title={"Nova viagem"}
                                        open={criarNovaviagem}
                                        handleClose={() => setCriarNovaViagem(false)}
                                        body={<NovaViagemComponent model={novaviagem} setModel={setNovaviagem} />}
                                        action={<ConfirmButtonNovaViagem setOpen={setCriarNovaViagem} confirmFunction={confirmCriarNovaViagem} />}
                                        width={"sm"}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container item xs={8} style={{ padding: "20px", marginTop: "-20px" }}>
                        <Grid container item xs={12} alignItems="center" spacing={2}>
                            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Pesquisar por título"
                                    onChange={(e) => handlesearchname(e)}
                                    placeholder="Pesquisar por titulo..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    style={{ borderRadius: "15px" }}
                                />
                            </Grid>
                            <Grid item xs={5} style={{ display: "flex", marginTop: "-7px" }}>
                                <UtaliiDatePickerInput
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "-7px" }}>
                                <Button variant="contained" color="primary" startIcon={<SearchIcon />} sx={{ height: "45px" }} onClick={() => getMinhasViagens()}>
                                    Pesquisar
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} style={{ marginTop: "10px" }}>
                            {currentItems?.map((value, index) => (
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: '0 0 0' }}
                                    timeout={(index + 1) * 300} // Atraso na transição para cada card
                                    key={index} // Use um identificador único do item aqui
                                >
                                    <Grid
                                        item xs={12}
                                        className={classes.card}
                                        style={{ marginTop: "inherit" }}
                                        onClick={() => handleClickViagem(value)}
                                        container
                                    >
                                        <Grid container item xs={6}>
                                            <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                <Typography style={{ fontWeight: "600" }}>{value.titulo}</Typography>
                                                <Typography style={{ color: "#637381" }}>{value?.qtd_despesas > 0 ? "Contém " + value.qtd_despesas + " despesas" : "Não há despesas registradas nesta viagem."}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} marginTop={5}>
                                                {formatarParaNomeStatusViagem(value.status)}
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <IniciadoEm datainicio={value.datainicio} />
                                                <CriadoPor />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0px 20px 0px 0px" }}>
                                            <Grid item xs={12} style={{ marginLeft: "auto" }}>
                                                <CreationDate data={value.datadecriacao} />
                                            </Grid>

                                            <Grid item xs={12} style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignContent: "flex-end",
                                                alignItems: "flex-end",
                                                flexDirection: "column"
                                            }}>
                                                {value.total !== 0 || value.total === null ? (
                                                    <>
                                                        {!value.tem_multimonetario && (
                                                            <>
                                                                <Typography>Total</Typography>
                                                                <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {(value.totalinicial !== 0 || value.totalinicial === null) &&
                                                            (
                                                                <>
                                                                    <Typography>Total inicial</Typography>
                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.totalinicial)}</Typography>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                                {value.tem_multimonetario ? (
                                                    <>
                                                        {formatarParaNomeStatusAdiantamentoListI(value.adiantamento)}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.totalinicial)}>
                                                            {formatarParaNomeStatusAdiantamentoList(value.adiantamento)}
                                                        </Tooltip>
                                                    </>
                                                )}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grow>
                            ))}
                            <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                                <Pagination
                                    color="primary"
                                    count={Math.ceil(filterViagens.length / itemsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    siblingCount={0}
                                    shape="rounded"
                                />
                            </Grid>
                            <DialogComponent
                                title={
                                    <>
                                        {typeDialog === 0 && viagem !== null ? (
                                            <Grid container item xs={12}>
                                                {viagem?.status === 8 ? (
                                                    <>
                                                        <Typography style={{ fontWeight: "600" }}>{viagem?.titulo}</Typography>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={11}>
                                                            <TextField
                                                                variant="standard"
                                                                fullWidth
                                                                defaultValue={viagem.titulo}
                                                                onChange={(e) => handleChangenameViagem(e)}
                                                                style={{ borderRadius: "15px" }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={1}>
                                                            <DialogComponent
                                                                title={viagem?.titulo}
                                                                open={openDeleteViagem}
                                                                handleClose={() => setOpenDeleteViagem(false)}
                                                                body={
                                                                    <Grid container item xs={12}>
                                                                        <Typography>Deseja realmente deletar esse viagem?</Typography>
                                                                    </Grid>
                                                                }
                                                                action={
                                                                    <Grid>
                                                                        <Button onClick={() => setOpenDeleteViagem(false)}>Cancelar</Button>
                                                                        <Button onClick={() => handleClickExcluirViagem(viagem.id)}>Confimar</Button>
                                                                    </Grid>
                                                                }
                                                                width={"sm"}
                                                            />
                                                            <IconButton onClick={() => setOpenDeleteViagem(true)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Grid>


                                                        {alterViagem.titulo !== "" ? (
                                                            <Grid item xs={12} style={{ textAlign: "right", justifyContent: "right" }}>
                                                                <Button
                                                                    variant="outlined"
                                                                    startIcon={<SaveIcon />}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => handleClickEditViagem()}
                                                                >
                                                                    Salvar
                                                                </Button>
                                                            </Grid>
                                                        ) : (
                                                            null
                                                        )}
                                                    </>
                                                )}
                                            </Grid>
                                        ) : (
                                            null
                                        )}
                                    </>
                                }
                                open={openViagem}
                                handleClose={() => { setOpenViagem(false); setViagem(null); }}
                                body={
                                    <>
                                        {viagem !== null && (
                                            <ViagemComponent
                                                viagem={viagem}
                                                handleCreate={handleClickCriarDespesa}
                                                handleEdit={handleClickEditDespesa}
                                                handleIniciarViagem={handleIniciarViagem}
                                                setTypeGlobal={setTypeDialog}
                                                handleDelete={handleDeleteDespesa}
                                                handleStart={handleIniciarViagem}
                                                handleFinalizar={handleFinalizarViagem}
                                                handleClickCreateHodometro={handleClickCreateHodometro}
                                                handleClickEditHodometro={handleClickEditHodometro}
                                            />
                                        )}
                                    </>
                                }
                                action={
                                    <>
                                        {typeDialog === 0 && viagem !== null ? (
                                            <ConfirmButtonViagem viagem={viagem} onClickEdit={handleClickEditViagem} setAlterViagem={setAlterViagem} />
                                        ) : (
                                            null
                                        )}
                                    </>
                                }
                                width={"sm"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}