export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function resizeImage(file, maxWidth, maxHeight, quality = 0.5) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    reader.onerror = reject;

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height = Math.floor((height * maxWidth) / width);
          width = maxWidth;
        } else {
          width = Math.floor((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      // Redimensiona a imagem com o canvas
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      // Reduz a qualidade da imagem
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Falha ao criar Blob a partir do Canvas'));
          }
        },
        'image/jpeg', // Usa JPEG para melhor compressão
        quality // Reduz a qualidade para reduzir o tamanho
      );
    };

    img.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export async function processImage(file) {
  try {
    const resizedBlob = await resizeImage(file, 1024, 1024, 0.5); // Define um limite de tamanho e qualidade
    const base64Image = await blobToBase64(resizedBlob);
    return base64Image;
  } catch (error) {
    console.error('Erro ao processar a imagem:', error);
    throw error;
  }
}
